.skelleton-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-medium);
  background-color: rgb(131, 131, 131);

  .skelleton-loader {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
  }
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
