@import './assets/scss/variables.scss';

.main__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 17rem;
}

.cover__all {
  // z-index: 7;
  // background: radial-gradient(circle, rgba($color: $kbreeder-brand, $alpha: 0.2) 0%, rgba($color: $kbreeder-brand, $alpha: 0.2) 12%, rgba(30, 26, 33, 0.7) 100%) repeat scroll 0% 0%;
}

.cover__all {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  backdrop-filter: blur(1px);

  background: radial-gradient(
      circle,
      rgba($color: $kbreeder-brand, $alpha: 0.2) 0%,
      rgba($color: $kbreeder-brand, $alpha: 0.2) 12%,
      rgba(30, 26, 33, 0.7) 100%
    )
    repeat scroll 0% 0%;

  // &-normal {
  //   background: radial-gradient(circle, rgba($color: $swapx-brand, $alpha: 0.4) 0%, rgba($color: $swapx-brand, $alpha: 0.4) 12%, rgba(30, 26, 33, 0) 100%) repeat scroll 0% 0%;
  //   background: radial-gradient(circle, rgba($color: $swapx-brand, $alpha: 0.2) 0%, rgba($color: $swapx-brand, $alpha: 0.2) 12%, rgba(30, 26, 33, 0.7) 100%) repeat scroll 0% 0%;
  // }

  // &-warning {
  //   background: radial-gradient(circle, rgba($color: $warning-red, $alpha: 0.1) 0%, rgba($color: $warning-red, $alpha: 0.1) 12%, rgba(30, 26, 33, 0.7) 40%) repeat scroll 0% 0%;
  // }
}
