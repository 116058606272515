.interval-selector {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: var(--chart-distance-xs);
  padding: var(--chart-distance-xxs);
  background-color: var(--chart-color-surface-2);
  border: 1px solid var(--chart-color-outline);
  border-radius: var(--chart-border-radius-xl);
}
