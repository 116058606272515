.chart-header {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;

  h4,
  h5 {
    font-family: var(--chart-font-text);
  }

  h4 {
    font-size: var(--chart-title-font-size-xl);
  }

  h5 {
    font-size: var(--chart-text-font-size-l);
  }
}
