@import 'src/assets/scss/animations';
@import 'src/assets/scss/variables';

$transition: 0.15s;

.btn {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  width: fit-content;
  white-space: nowrap;
  border: none;
  border-radius: var(--border-radius-medium);
  -webkit-border-radius: var(--border-radius-medium);
  -moz-border-radius: var(--border-radius-medium);
  cursor: pointer;
  transition: color $transition ease-out, border $transition ease-out, opacity $transition ease-out,
    background $transition ease-out;

  font-family: var(--title-font);
  font-size: 1rem;
  line-height: 1em;

  // Break line on long words
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    transition: color $transition ease-out, border $transition ease-out, opacity $transition ease-out,
      background $transition ease-out;
  }

  &__primary {
    color: var(--text);
    font-weight: bold;
    background: #502d63;

    // border: 0.3rem solid var(--text);
    &:hover:not(.btn-disabled) {
      background: #683f7d;
    }

    &-border {
      border-radius: var(--border-radius);
      border: 1.5px solid currentColor;
    }
  }

  &__secondary {
    background: none;
    color: $kbreeder-violet;
    border: 0.2rem solid var(--kbreeder-brand);
    gap: 1rem;

    &:hover:not(.btn-disabled) {
      border-color: var(--kbreeder-brand);
      color: var(--kbreeder-pink);
      background-color: var(--kbreeder-brand);
    }
  }

  // TODO compare with styles of WalletButton.module.scss
  &__wallet {
  }

  &__small {
    font-family: var(--text-font-2);
    font-size: var(--medium-text-font-size);
    line-height: 1em;

    border-radius: var(--border-radius);
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);

    padding: 0.5rem 1rem;
    &-longer {
      padding: 0.7rem 1.5rem;
    }
  }

  &__medium {
    font-family: var(--title-font);
    font-size: var(--small-title-font-size);
    line-height: var(--title2-line-height);
    padding: 1rem;
    &-longer {
      padding: 0.7rem 1.5rem;
    }
  }

  &__large {
    font-family: var(--title-font);
    font-size: var(--title2-font-size);
    line-height: var(--title2-line-height);
    padding: 1rem;
    &-longer {
      padding: 0.7rem 1.5rem;
    }
  }

  &-full {
    width: 100%;
  }

  &-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
