.main__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.chart-and-pool-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, clamp(260px, 100%, 70rem));
  justify-content: center;
  gap: 2rem;
  width: clamp(260px, 98vw, 1580px);
}

.pool__info__wrapper {
  width: clamp(260px, 98vw, 70rem);
}
