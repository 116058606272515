.wallet-button {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &.large {
    gap: 2rem;
  }

  .wallet__logo {
    width: 2.5rem;
    height: 2.5rem;

    &.large {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}
