@import '../../assets/scss/variables.scss';

$burguerMenuScreenSize: 1150px;

.navbar {
  position: fixed;
  width: 100vw;
  min-width: 280px;
  z-index: 2;

  &_background {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    align-content: center;
    width: 100vw;
    padding: calc(var(--margin-bottom) / 2);
    padding-inline: 6rem;
    border-bottom: 1px solid var(--kbreeder-brand);
    background: rgba($color: $bg-6, $alpha: 0.7);
    backdrop-filter: blur(10px);
  }

  .options__container {
    // position: relative;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    align-content: center;

    @media screen and (max-width: $burguerMenuScreenSize) {
      display: none;
    }

    ul {
      display: flex;
      flex-direction: row;
      gap: 3rem;
      justify-content: space-between;
      margin-left: 6rem;
      a {
        font-weight: bold;
        color: lighten($color: $kbreeder-brand, $amount: 5%);
        transition: color 0.2s ease-out;
        &.active-nav-link {
          color: lighten($color: $kbreeder-brand, $amount: 15%);
        }
        &:hover {
          color: $kbreeder-violet;
        }
      }
    }

    &__burguerMenu {
      position: absolute;
      top: 0;
      right: 0;
      // right: -30rem;
      // display: none;
      flex-direction: column;
      justify-content: left;
      padding: 2rem;
      height: 100vh;
      width: 30rem;
      max-width: 100vw;
      background: rgba($color: $bg-6, $alpha: 0.7);
      backdrop-filter: blur(10px);
      box-shadow: -1px 0 5px rgba($color: $kbreeder-brand, $alpha: 0.5),
        -1px 0 20px rgba($color: $kbreeder-brand, $alpha: 0.5);
      z-index: 1;
      transition: transform 0.3s ease-out;

      &__close {
        margin-left: auto;
        cursor: pointer;
        transition: color 0.2s ease-out;

        // Avoid user selection
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:hover {
          color: $kbreeder-violet;
        }
      }

      @media screen and (min-width: $burguerMenuScreenSize) {
        display: none;
      }

      &.active {
        display: flex;
        // transform: translateX(-30rem);
        transition: transform 0.15s ease-out, display 0s ease;
      }

      ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: left;
        margin: 10rem 0 4rem;
        gap: 4rem;
        font-size: var(--subtitle-font-size);

        a {
          font-weight: bold;
          color: lighten($color: $kbreeder-brand, $amount: 5%);
          transition: color 0.15s ease-out;
          &.active-nav-link {
            color: lighten($color: $kbreeder-brand, $amount: 15%);
          }
          &:hover {
            color: $kbreeder-violet;
          }
        }
      }

      .wallet__button__noLabel {
        width: fit-content;
        // min-width: unset;
        color: var(--title);
        border-color: var(--text);

        &:hover {
          color: $kbreeder-pink;
          border-color: $kbreeder-violet;
        }
      }
    }
  }

  .kumaBreeder__logo {
    height: 5.5rem;

    // Avoid user selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.mla {
  margin-left: auto;
}

.address__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 0.5rem;
  width: fit-content;
  background-color: $kbreeder-brand;
  padding: 1rem 2rem;
  border-radius: var(--border-radius-round);
  cursor: pointer;
  transition: color 0.15s ease-out, background 0.15s ease-out;
  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    color: $kbreeder-violet;
  }

  &__address {
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 16rem;
    @media screen and (min-width: 376px) {
      display: unset;
    }
  }

  &__walletLogo {
    display: unset;
    height: 2.5rem;
    width: 2.5rem;
    color: var(--brand);
    @media screen and (min-width: 376px) {
      display: none;
    }
  }
}

.wallet__button {
  &-label {
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  &-noLabel {
    display: none;

    @media screen and (max-width: 450px) {
      display: unset;
    }
  }
}

.cover__all {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background: rgba($color: $brand, $alpha: 0.4);
  z-index: -1;
  transition: z-index 0.15s ease-out;

  @media screen and (min-width: $burguerMenuScreenSize) {
    display: none;
  }

  &.active {
    z-index: 5;
  }
}

.burguerMenu__button {
  display: none;
  cursor: pointer;

  color: rgba($color: $kbreeder-violet, $alpha: 1);
  transition: color 0.15s ease-out;

  &:hover {
    color: lighten($color: $kbreeder-brand, $amount: 10%);
  }

  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media screen and (max-width: $burguerMenuScreenSize) {
    display: flex;
  }
}

.noUserSelect {
  // Avoid user selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
