:root {
  --chart-font-title: var(--title-font);
  --chart-font-text: var(--text-font);

  --chart-title-font-size-s: 1.6rem;
  --chart-title-font-size-m: 1.8rem;
  --chart-title-font-size-l: 2rem;
  --chart-title-font-size-xl: 2.4rem;

  --chart-title-line-height: 2.05em;

  --chart-text-font-size-s: 1.2rem;
  --chart-text-font-size-m: 1.4rem;
  --chart-text-font-size-l: 1.6rem;

  --chart-text-line-height: 1.6em;
}
