.chart-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--chart-color-background);
  border-radius: var(--chart-border-radius-xl);
  overflow: hidden;

  .chart-top-section,
  .chart-bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--chart-distance-s);
  }

  .chart-top-section {
    align-items: center;
    padding-bottom: 0;
  }

  .chart-bottom-section {
    padding-top: 0;
  }
}

.chart-title {
  font-family: var(--chart-font-title);
  line-height: var(--chart-title-line-height);
  font-size: var(--chart-title-font-size-s);

  &.chart-title__price {
    font-family: var(--chart-font-text);
    line-height: 1em;
    font-size: var(--chart-title-font-size-xl);
  }
}
