@import '@/assets/scss/variables';

.cover__all {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  backdrop-filter: blur(1px);

  &-normal {
    background: radial-gradient(
        circle,
        rgba($color: $swapx-brand, $alpha: 0.4) 0%,
        rgba($color: $swapx-brand, $alpha: 0.4) 12%,
        rgba(30, 26, 33, 0) 100%
      )
      repeat scroll 0% 0%;
    background: radial-gradient(
        circle,
        rgba($color: $swapx-brand, $alpha: 0.2) 0%,
        rgba($color: $swapx-brand, $alpha: 0.2) 12%,
        rgba(30, 26, 33, 0.7) 100%
      )
      repeat scroll 0% 0%;
  }

  &-warning {
    background: radial-gradient(
        circle,
        rgba($color: $warning-red, $alpha: 0.1) 0%,
        rgba($color: $warning-red, $alpha: 0.1) 12%,
        rgba(30, 26, 33, 0.7) 40%
      )
      repeat scroll 0% 0%;
  }
}

.wildcardRef {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-height: 520px) {
    top: unset;
    bottom: 0;
    // right: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: 90vh;
  }
}
