.button {
  padding: var(--chart-distance-xs) var(--chart-distance-s);
  background-color: transparent;
  border: none;
  color: color-mix(in srgb, var(--chart-color-on-surface), transparent 30%);
  border-radius: 100rem;
  font-family: var(--chart-font-text);
  font-size: var(--chart-text-font-size-m);
  cursor: pointer;

  &:hover {
    color: var(--chart-color-on-surface);
  }

  &.button__selected {
    color: var(--chart-color-on-surface);
    background-color: color-mix(in srgb, var(--chart-color-on-surface), transparent 80%);
  }
}
