.main-container {
  --element-height: 7px;
  --element-border-radius: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 260px;
  height: 100%;
  min-height: var(--chart-min-height);

  .chart-and-price-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex: 1;

    .chart-container {
      width: 100%;
    }

    .price-container {
      display: flex;
      flex-direction: column;
      width: fit-content;
      justify-content: space-between;
      padding-inline: 10px;
      padding-bottom: 30px;

      .price-element {
        width: 30px;
        height: var(--element-height);
        border-radius: var(--element-border-radius);
        background-color: black;
      }
    }
  }

  .time-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-right: 80px;
    padding-left: 10px;

    .time-element {
      width: min(15%, 60px);
      height: var(--element-height);
      border-radius: var(--element-border-radius);
      background-color: black;
    }
  }
}
