$brand: #ff8400;
$swapx-brand: #1069bb;
$swapx-brand-clearer: hsl(209, 40%, 40%);
$swapx-brand-darker: hsl(209, 84%, 20%);
$swapx-grey: #313541;
$swapx-grey-lighter: hsl(225, 14%, 52%);
$dbreeder-brand: #038726;
$warning-red: hsl(0, 98%, 60%);
$warning-red-darker: hsl(0, 98%, 52%);
$error-yellow: hsl(48, 100%, 45%);
$error-yellow-darker: hsl(48, 100%, 30%);
$bg-4: #141823;
$bg-5: hsl(228, 29%, 7%);
$bg-6: #0d1218;
$title: hsl(0, 1%, 90%);

$color-eth: #48cbd9;
$color-bsc: #f0b90b;
$color-polygon: #5d52ab;
$color-avalanche: #e84142;
$color-fantom: #1969ff;
$color-arbitrum: #39f;

$kbreeder-bg-1: #161722;
$kbreeder-white: #cfd1d9;
$kbreeder-brand: #4e2863;
$kbreeder-pink: #ff74a4;
$kbreeder-violet: #9f6ea3;

:root {
  --brand: #ff8400;
  --brand-darker: hsla(31, 100%, 45%, 1);
  --swapx-brand: #1069bb;
  --swapx-brand-clearer: hsl(209, 40%, 40%);
  --swapx-brand-lighter: hsl(209, 84%, 45%);
  --swapx-brand-darker: hsl(209, 84%, 20%);
  --swapx-brand-transparent: hsla(209, 84%, 40%, 0.25);
  --dbreeder-brand: #038726;
  --text-link: rgba(255, 132, 0, 0.85);
  --title: hsl(0, 1%, 90%);
  --text: #d6d5d7;
  --text-blue: #3391ff;
  --shadow: #303436;
  --silver: #9a9898;
  --transparent: rgba(0, 0, 0, 0);
  --warning-red: hsl(0, 98%, 60%);
  --warning-red-darker: hsl(0, 98%, 52%);
  --error-yellow: hsl(48, 100%, 45%);
  --error-yellow-darker: hsl(48, 100%, 30%);

  --brand-rgb: 255, 132, 0;
  --title-opacity70: rgba(255, 255, 255, 0.7);

  --kbreeder-bg-1: #161722;
  --kbreeder-white: #cfd1d9;
  --kbreeder-brand: #4e2863;
  --kbreeder-pink: #ff74a4;
  --kbreeder-violet: #9f6ea3;

  --bg-1: #121212;
  --bg-1-lighter: hsl(0, 0%, 14%);
  --bg-2: #051d3d;
  --bg-2-lighter: hsla(214, 85%, 19%, 1);
  --bg-3: hsl(215, 49%, 22%);
  // * Swap colors
  --bg-4: #141823;
  --bg-5: hsl(228, 29%, 7%);
  --bg-6: #0d1218;
  --swapx-grey: #313541;
  --swapx-grey-lighter: hsl(225, 14%, 60%);

  // --bg-5: hsl(224, 27%, 15%);
  // --bg-6: hsl(224, 27%, 15%);
  // --bg-4: hsl(213, 30%, 9%);

  /* Loading animation cubic beizer */
  --loading-beizer: cubic-bezier(0.51, 0.45, 0.51, 1.08);

  /* Margin for the top of a row */
  --margin-top: 18.95rem;
  /* Margin for the bottom of a row */
  --margin-bottom: 3.52rem;
  /* Margin to separate different content inside of a row */
  --margin-same-row: 13.42rem;
  /* Margin to separate different content inside of a row. Shorter */
  --margin-same-row-shorter: 9.6rem;
  /* Margin for the subtitle text, the button linked to a text, etc. */
  --margin-subtitle: 3.52rem;

  --margin-token-container: 1.76rem;

  --padding-options-menu: 4.5rem;
  --padding-options-menu-smaller: 2rem;

  --padding-swap-container-horizontal: 1.5rem;
  --padding-swap-container-vertical: 2.2rem;
  --padding-swap-container-head-vertical: 3.3rem;

  /* Border radius for inputs */
  --border-radius-small: 0.5rem;
  /* Border radius for buttons */
  --border-radius: 1.4rem;
  /* Border radius for secondary containers or menus */
  --border-radius-medium: 2rem;
  /* Brorder radius for the main container */
  --border-radius-large: 2.7rem;
  /* Border radius for round components */
  --border-radius-round: 1000rem;

  /* SWAP X SIZES */
  --button-height: 4rem;

  --title-font: 'Future-Earth';
  --text-font: 'Xolonium';
  --text-font-2: 'Inter-Regular';
  // --text-font-2: "Futura-Bold";
  // --text-font-2: "Arial";
  /*
  Title - Opens a row
  Title2 - References to something important inside a row
            eg. Product Cards title, Buy button
  Subtitle1 - For lists titles
  Subtitle2 - Text directly after a Title and explaining it a bit further
  Text - Paragraphs, lists, etc.
  */

  /* Future-Earth font */
  --title-font-size: 2.8rem;
  --title-line-height: 2.05em;
  --title2-font-size: 1.9rem;
  --title2-line-height: 2.05em;
  --small-title-font-size: 1.44rem;
  --small-title-line-height: 2.05em;

  /* Xolonium font */
  --subtitle-font-size: 2.4rem;
  --subtitle-line-height: 1.4em;
  --text-font-size: 1.9rem;
  --text-line-height: 1.4em;

  /* Inter font */
  --small-title-font-size-2: 2.3rem;
  --small-title-line-height-2: 1.2em;
  --text-font-size-2: 1.9rem;
  --text-line-height-2: 1.2em;
  --medium-text-font-size: 1.6rem;
  --inter-line-height: 1.2em;
  --small-text-font-size: 1.3rem;
  --small-text-line-height: 1.2em;

  @media screen and (max-width: 366px) {
    --title-font-size: 2.8rem;
  }
}

/*
.dark-mode {
  --brand: #f6a32f;
  --brand-rgb: 246, 163, 47;
  --text-link: rgba(255, 166, 0, 0.75);
  --text: #000000;
  --text-third: #5e7c9c;
  --text-third-hover: #2669bf;
  --text-forth: #315271;
  --shadow: #000000d9;
  --bg: rgba(255, 255, 255, 1);
  --bg: linear-gradient(0.25turn, rgb(255, 255, 255), #051e3e, #051e3e, rgb(255, 255, 255));
  --silver: #9a9898;
}
*/
