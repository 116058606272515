:root {
  --chart-border-radius-m: var(--border-radius);
  --chart-border-radius-l: var(--border-radius-medium);
  --chart-border-radius-xl: var(--border-radius-large);

  --chart-distance-xxs: 0.5rem;
  --chart-distance-xs: 0.75rem;
  --chart-distance-s: 1.25rem;
  --chart-distance-m: 2rem;
  --chart-distance-l: 3rem;
  --chart-distance-xl: 4rem;
  --chart-distance-xxl: 5rem;

  --chart-min-height: 350px;
}
