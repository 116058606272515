.chart-container {
  position: relative;
  width: 100%;
  z-index: 0;
  min-height: 300px;

  .chart {
    position: relative;
    z-index: 1;
  }
}
